import styled from "styled-components/macro";
import { InputErrorDiv as InputErrorDivBase } from "./../../../../containers/PatientImport/styles";

export const FlaggedIssuesContainer = styled.div`
  margin-top: 20px;
  padding: 24px;
  border: solid 2px ${({ theme }) => theme.warningToastColor};

  h3 {
    color: ${({ theme }) => theme.primaryTextColor};
  }
  p {
    color: ${({ theme }) => theme.primaryTextColor};
    padding-bottom: 20px;
    padding-top: 10px;
  }
  tr {
    color: #2c2c2c;
    font-size: 14px;
  }
  table {
    margin-bottom: 20px;
  }

  td:nth-child(2),
  th:nth-child(2) {
    padding-left: 20px;
  }

  td:nth-child(3) {
    color: ${({ theme }) => theme.errorDarkColor};
    font-weight: bold;
  }
`;

export const InputErrorDiv = styled(InputErrorDivBase)`
  margin-top: 4px;
`;
