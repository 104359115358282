export default {
  additionalDetails: "Additional Details",
  authVet: "Authorizing Veterinarian",
  autoship: "Recommend Autoship?",
  clientShortcutLabel: "Notes to Client Shortcuts",
  details: "Prescribing Details",
  directions: "Directions",
  eSignature: "By clicking authorize, you agree that your electronic signature is the legal equivalent of your handwritten signature, and that you are a veterinarian or an authorized delegate of a veterinarian who has established a Veterinary Client Patient Relationship (VCPR) with the patient, as it is defined by your applicable state law(s).",
  choseStateLicense: "Select a license to use for this script based upon the state or province I am practicing medicine in and where the patient lives.",
  expDateLabel: "Expiration Date",
  finishScriptTitle: "Finish Script",
  optionalHelpText: "Optional",
  notesToClient: "Notes to Client",
  notesToPharmacy: "Notes to Pharmacy",
  notifDateLabel: "Notification Date",
  refillLabel: "Refill",
  shortcutButtonText: "add new shortcut",
  sigShortcutLabel: "SIG Shortcuts",
  flavor: "Flavor",
  UPFLabel: "Units Per Fill",
  validations: {
    unitsPerFillZero: "Units per fill must be greater than 0",
    unitsPerFillMax: "Units per fill must not exceed 999",
    dateInPast: "Expiration Date must be in the future.",
    expOneYearGreater: "Expiration Date cannot be greater than one year.",
    required: "Required",
    notesToClient: "Notes to Client must be at most 1000 characters",
    notesToPharmacy: "Notes to Pharmacy must be at most 512 characters",
    directions: "Directions must be at most 180 characters",
    reasonMax: "Reason must be at most 512 characters",
    reasonMin: "Reason must be at least 5 characters",
    contExpSixMonthsGreater:
      "The prescribed item is a controlled substance, so expiration date must be within 6 months.",
    problemWithScript: "Prescription Management detected a potential issue with this script.",
    authorizingVet: {
      vetLicenseExpiring: "Veterinarian License Expiring Soon",
      vetLicenseExpired: "Veterinarian License Expired",
      vetLicenseInvalid: "Veterinary License on file is invalid",
      deaLicenseInvalid: "DEA license does not exist or is inactive",
      deaLicenseExpiring: "DEA license is within 30 days of expiring",
      deaLicenseExpired: "DEA license has expired",
      staffBasicDelegate:
        "You need advanced delegate permissions for this Veterinarian to authorize a script for this medication.",
      vetDelegate:
        "You must be a Veterinarian with an active and valid DEA license to submit this script.",
    },
    backorderedProduct: "This product is currently on backorder, you may prescribe so it can be ordered when available.",
    productUnavailable: "This product is not available. You need to select a different product to proceed.",
    resolve: "Resolve!",
    medicalRationaleRequired: "Conflict: Medical Rationale needed to ensure compliance with GFI 256."
  },
  submitRxError: "An error occurred during submission of your prescription.  Please try again or contact support.",
  competitiveFaxLabel: "Prescription came from another pharmacy",
  competitiveFaxHelpText: "This will send a tailored email for their recommended fill.",
  workflowStatusLabel: "Workflow Status",
  refillMessage: (unitsPerFill: number | 0, refill: number) => {
    return `Total Quantity with Refills: 
      ${refill < 0 ? Infinity : refill ? refill * unitsPerFill + unitsPerFill : unitsPerFill}`;
  },
};
