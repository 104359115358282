import { Formik, useFormikContext } from "formik";
import * as r from "ramda";
import * as H from "history";
import React, { useEffect, useState } from "react";
import { AutoshipIcon } from "../../../assets/AutoshipIcon";
import { SubmitRxMutationFn, usegetSigShortcutsQuery } from "../../../data/gql/apiTypes";
import DropdownSelector from "../../DropdownSelector";
import { Input } from "../../StyledComponents/Input/Input";
import { InputLabel } from "../../StyledComponents/InputLabel/InputLabel";
import messages from "../messages";
import flaggedIssuesMessages from "../Subcomponents/FlaggedIssuesError/messages";
import {
  AutoshipDiv,
  FieldColContainer,
  HelpLabel,
  FillMessageLabel,
  InputInlineWrapper,
  InputWrapper,
  SpacerDiv,
  SpacerErrorDiv,
  SubmitRxBtn,
  SubTitleH2,
  TopPaddingSpacer,
} from "../styles";
import CompetitiveFax from "../Subcomponents/CompetitiveFax";
import Expiration from "../Subcomponents/Expiration";
import FieldError from "../Subcomponents/FieldError";
import FlaggedIssuesError from "../Subcomponents/FlaggedIssuesError/index";
import { FlaggedGridValues } from "../Subcomponents/FlaggedIssuesError/types";
import NotesShorcuts from "../Subcomponents/NotesShortcuts";
import NotesToClient from "../Subcomponents/NotesToClient";
import AuthorizingVet from "../Subcomponents/AuthorizingVet";
import StateLicenseSelect from "../Subcomponents/StateLicense/StateLicenseSelect";
import { Notification } from "../Subcomponents/NotificationField/Notification";
import UnitsPerFillField from "../Subcomponents/UnitsPerFillField/UnitsPerFillField";
import { getFormSchema, getFlavorsHaveLength, getInitialValues, transformRxForApi, getSmartScribeError } from "./formHelpers";
import { FinishScriptFormVals, Props } from "./types";
import { useHistory, useParams } from "react-router-dom";
import { LinkedGuids } from "../types";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { addToast } from "../../../actions/toast";
import { generateToastID } from "../../Toast/Toast";
import ApproveWithChangesDialog from "../../ApproveWithChangesDialog";
import { WorkFlowType } from "../../CollapsableCard/types";
import FieldWarning from "../Subcomponents/FieldWarning";
import { getAuthorizingVetWarningRx } from "../Subcomponents/AuthorizingVet/utils";

const Form = (formProps: Props) => {
  const {
    sendButton,
    reviewRx,
    hooksAndState: { setDirections, setShouldValidateOnAction, disableSubmit },
    pendingRequestType,
    setReviewRx,
  } = formProps;
  const { handleChange, handleSubmit, setFieldValue, errors, submitCount, values, isSubmitting, isValidating } =
    useFormikContext<FinishScriptFormVals>();

  // Only validate after first submit
  useEffect(() => {
    if (submitCount > 1) {
      setShouldValidateOnAction(true);
    }
  }, [submitCount, setShouldValidateOnAction]);

  // Scroll Errors into view
  useEffect(() => {
    const keys = r.keys(errors);

    if (keys.length && isSubmitting && !isValidating) {
      // Find first error element
      const errorEl = document.querySelector(`label[for="${keys[0]}"]`);

      if (errorEl) {
        errorEl.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  const flavorCodes = reviewRx.flavorCodes || [];
  const flavorsHaveLength = getFlavorsHaveLength(flavorCodes);
  const packageQuantity = reviewRx.packageQuantity || null;
  const calculateTotalRefills = (totalRefills: string) => {
      if(totalRefills === 'PRN'){
        values.isPrn = true;
        return 0;
      }
      values.isPrn = false;
      return parseInt(totalRefills || "0")
  }

  const [units, setUnits] = useState(reviewRx.units);
  const [refill, setRefill] = useState(isNaN(calculateTotalRefills(values.refills)) ? -1 : calculateTotalRefills(values.refills));
  const [unitsPerFill, setUnitsPerFill] = useState(reviewRx.defaultSupplyCount * units);
  const isApprovalTypeRequest = pendingRequestType === WorkFlowType.APPROVALS;
  const isRenewalTypeRequest = pendingRequestType === WorkFlowType.RENEWALS;
  const isApproveOrRenewNoChanges = (isApprovalTypeRequest || isRenewalTypeRequest) && !reviewRx.authorizedWithChanges;
  values.acknowledgeErrors = getSmartScribeError(formProps?.flaggedGridValues);

  const getVetLicense = (vetKey: string) => {
    const vet = reviewRx.authorizingVets.find(x => x.veterinarianKey === vetKey);
    values.veterinarianLicenseKey = vet?.primaryVetLicenseNumber ? vet?.primaryVetLicenseNumber : "";
    return vet?.primaryVetLicenseNumber ? vet?.primaryVetLicenseNumber : "";
  }

  if(formProps.reviewRx.authorizingVet && values.veterinarianLicenseKey === ""){
    getVetLicense(formProps.reviewRx.authorizingVet);
  }

  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" id="legalCategoryCode" value={reviewRx.legalCategoryCode} />
      {flavorsHaveLength && (
        <DropdownSelector
          id="flavor"
          inputLabel={messages.flavor}
          value={values.flavor}
          onChange={handleChange}
          opts={r.map(
            (flavor) => ({
              value: flavor.flavorCode,
              label: flavor.flavorCode,
            }),
            flavorCodes,
          )}
        />
      )}
      <InputInlineWrapper>
        <FieldColContainer>
          <UnitsPerFillField
            errors={errors}
            handleChange={(e: React.BaseSyntheticEvent) => {
              handleChange(e);
              setUnits(e.target.value);
              setUnitsPerFill(reviewRx.defaultSupplyCount * e.target.value);
            }}
            defaultSupplyCount={reviewRx.defaultSupplyCount}
            packageQuantity={packageQuantity}
            defaultPerFill={reviewRx.units}
            disabled={isApproveOrRenewNoChanges}
          />
        </FieldColContainer>
        <FieldColContainer>
          <DropdownSelector
            id="refills"
            inputLabel={messages.refillLabel}
            required={true}
            onChange={(e: React.BaseSyntheticEvent) => {
              handleChange(e);
              setRefill(e.target.value);
              if (isNaN(e.target.value)) {
                setRefill(-1);
              }
            }}
            value={values.refills}
            opts={r.map(
              (refills) => ({
                value: refills,
                label: refills,
              }),
              reviewRx.numberOfRefills,
            )}
            helpFooter={<FillMessageLabel>{messages.refillMessage(unitsPerFill, refill)}</FillMessageLabel>}
            disabled={isApproveOrRenewNoChanges}
          />
          {errors.refills ? <FieldError error={errors.refills} /> : <SpacerErrorDiv />}
        </FieldColContainer>
      </InputInlineWrapper>
      {!isApprovalTypeRequest && (
        <Notification errors={errors} handleChange={handleChange} isRenewalTypeRequest={isRenewalTypeRequest} />
      )}
      <Expiration handleChange={handleChange} errors={errors} fullWidth defaultValue={values.expiration} />
      <SubTitleH2>{messages.additionalDetails}</SubTitleH2>
      <DropdownSelector
        id="sigShortcuts"
        inputLabel={messages.sigShortcutLabel}
        errors={errors.sigShortcuts}
        value={values.sigShortcuts}
        onChange={(e: React.BaseSyntheticEvent) => {
          handleChange(e);
          setDirections(e.target.value);
          setFieldValue("directions", e.target.value, false);
        }}
        useOptionsGetter={usegetSigShortcutsQuery}
        queryVars={{}}
        getOptions={(results) =>
          (results.retrieveSIGShortcuts || []).map((row) => ({
            value: row?.note || "",
            label: row?.label || "",
          }))
        }
        tooltip={{ message: "No Shortcuts Found", customStyles: { isElHalfWidth: true, isTriggerFullWidth: true } }}
      />
      <InputWrapper>
        <InputLabel htmlFor="directions" label={messages.directions} required={true} errors={errors.directions} />
        <Input
          id="directions"
          name="directions"
          type="text"
          value={values.sigShortcuts || values.directions}
          fullWidth
          onChange={(e) => {
            setFieldValue("sigShortcuts", "", false);
            setDirections(e.target.value);
            setReviewRx({directions: e.target.value})
            handleChange(e);
          }}
          errors={errors.directions}
          warnings={flaggedIssuesHasDirections(formProps.flaggedGridValues)}
        />
        {errors.directions ? <FieldError error={errors.directions} /> : <SpacerErrorDiv />}
      </InputWrapper>
      <NotesShorcuts
        onChange={(e: React.BaseSyntheticEvent) => {
          setFieldValue("notesToClient", e.target.value, false);
          handleChange(e);
        }}
        value={values.notesToClientShortcuts}
        errors={errors.notesToClientShortcuts}
      />
      <NotesToClient
        onChange={(e) => {
          setFieldValue("notesToClientShortcuts", "", false);
          handleChange(e);
        }}
        value={values.notesToClientShortcuts || values.notesToClient}
        errors={errors.notesToClient}
      />
      <InputWrapper>
        <InputLabel htmlFor="notesToPharmacy" label={messages.notesToPharmacy} errors={errors.notesToPharmacy} />
        <Input
          id="notesToPharmacy"
          name="notesToPharmacy"
          type="text"
          fullWidth
          onChange={handleChange}
          errors={errors.notesToPharmacy}
        />
        <HelpLabel>{messages.optionalHelpText}</HelpLabel>
        {errors.notesToPharmacy ? <FieldError error={errors.notesToPharmacy} /> : <SpacerErrorDiv />}
      </InputWrapper>
      <CompetitiveFax onChange={handleChange} />
      {/* Show Autoship Toggle option for non-approval requests */}
      {!isApprovalTypeRequest &&
        <InputWrapper>
          <AutoshipDiv>
            <AutoshipIcon />
            {messages.autoship}
            <label>
              <input
                onChange={handleChange}
                id={"autoship"}
                name={"autoship"}
                type="checkbox"
                defaultChecked={reviewRx.autoship}
              />
              <span />
            </label>
          </AutoshipDiv>
        </InputWrapper>
      }{" "}
      <InputWrapper>
        <AuthorizingVet
          authorizingVets={reviewRx.authorizingVets}
          errors={errors.authorizingVet || errors.veterinarianLicenseKey}
          onChange={(e: React.BaseSyntheticEvent) => {
            values.authorizingVet = e.target.value;
            setReviewRx({ authorizingVet: e.target.value, veterinarianLicenseKey: "" });
            setFieldValue("veterinarianLicenseKey", getVetLicense(e.target.value), true);
            handleChange(e);
          }}
          value={values.authorizingVet}
        />
        <TopPaddingSpacer>
        <StateLicenseSelect
          authorizingVet={reviewRx.authorizingVets.filter(v => v.veterinarianKey === values.authorizingVet)?.[0]}
          onChange={(e: React.BaseSyntheticEvent) => {
            setReviewRx({ veterinarianLicenseKey: e.target.value });
            setFieldValue("veterinarianLicenseKey", e.target.value, true);
            handleChange(e);
          }}
          value={values.veterinarianLicenseKey}
        />
        </TopPaddingSpacer>
        <TopPaddingSpacer>
          {getAuthorizingVetWarningRx(values.authorizingVet, reviewRx.authorizingVets, reviewRx.isControlled, values.veterinarianLicenseKey ?? "").map(
            (warning, index) => (
              <FieldWarning key={index} warning={warning.message} />
            ),
          )}
        </TopPaddingSpacer>
      </InputWrapper>
      {flaggedIssuesHasLength(formProps?.flaggedGridValues) ? (
        <FlaggedIssuesError values={values} comparisonGrid={formProps.flaggedGridValues} handleChange={handleChange} errors={errors} />
      ) : null}
      {reviewRx.authorizedWithChanges ? (
        <ApproveWithChangesDialog
          reason={values.approveWithChangeReason}
          note={values.approveWithChangeClientNote}
          reasonError={errors.approveWithChangeReason}
          noteError={errors.approveWithChangeClientNote}
          setReason={(reason) => {
            setFieldValue("approveWithChangeReason", reason, false);
          }}
          setNote={(note) => {
            setFieldValue("approveWithChangeClientNote", note, false);
          }}
        />
      ) : null}

      <SpacerDiv>
        <SubmitRxBtn type={"submit"} btnStyle={"primary"} disabled={disableSubmit}>
          {sendButton}
        </SubmitRxBtn>
      </SpacerDiv>
    </form>
  );
};

const handleOnSubmit = (
  values: FinishScriptFormVals,
  submitRx: SubmitRxMutationFn,
  claimCheckKey: string,
  linkedGuids: LinkedGuids,
  setSubmitting: (isSubmitting: boolean) => void,
  setIsFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch<AnyAction>,
  history: H.History<any>,
  defaultSupplyCount: number,
  prescriptionGuid?: string,
) => {
  const variables = transformRxForApi(values, linkedGuids, claimCheckKey, defaultSupplyCount);
  setIsFormSubmitting(true);
  submitRx({
    variables,
  })
    .then(() => {
      setSubmitting(false);
      setIsFormSubmitting(false);
      if (prescriptionGuid) {
        history.push(`/rx-success/${claimCheckKey}/${prescriptionGuid}`);
      } else {
        history.push(`/rx-success/${claimCheckKey}`);
      }
    })
    .catch((e) => {
      setSubmitting(false);
      setIsFormSubmitting(false);
      if (e && e.toString().includes("Approval request already approved.")) {
        history.push({
          pathname: `/rx-success/${claimCheckKey}/${prescriptionGuid}`,
          state: "HAS_PROCESS_BY_ANOTHER_USER"
        });
      } else {
        const errorMessage = `${messages.submitRxError}`;
        dispatch(
          addToast({
            message: errorMessage,
            id: generateToastID(),
            kind: "error",
          }),
        );
      }
    });
};

const flaggedIssuesHasLength = (flaggedGridValues: FlaggedGridValues[]): boolean => r.length(flaggedGridValues) > 0;
const flaggedLabels = flaggedIssuesMessages.flaggedLabels;
const flaggedIssuesHasDirections = (flaggedGridValues: FlaggedGridValues[]): boolean => {
  const labels = r.map(({ label }) => label, flaggedGridValues);
  return r.includes(flaggedLabels.directionsKeywords, labels);
};

export const ScriptForm = (props: Props) => {
  const {
    reviewRx,
    hooksAndState: { shouldValidateOnAction, setIsFormSubmitting },
  } = props;

  const { claimCheckKey, prescriptionGuid } = useParams<{ claimCheckKey: string; prescriptionGuid?: string }>();

  const history = useHistory();

  const dispatch = useDispatch();

  const validationSchema = getFormSchema(props.flaggedGridValues, reviewRx);
  

  return (
    <Formik
      initialValues={getInitialValues(reviewRx)}
      validationSchema={validationSchema}
      validateOnChange={shouldValidateOnAction}
      validateOnBlur={shouldValidateOnAction}
      onSubmit={(values, { setSubmitting }) => 
        handleOnSubmit(
          values,
          props.submitRx,
          claimCheckKey,
          reviewRx.linkedGuids,
          setSubmitting,
          setIsFormSubmitting,
          dispatch,
          history,
          reviewRx.defaultSupplyCount || 1,
          prescriptionGuid,
        )
      }
    >
      {() => <Form {...props} />}
    </Formik>
  );
};
