import { ProductT, PatientT, ClientT, FullPracticeUserT, ApprovalT, RenewalT, ValidateSmartScribeT, BaseProductT } from "../../data/gql/apiTypes";
import { FieldsForConflict, Props } from "../../components/FinishScriptForm/types";
import {
  FlaggedIssues,
  SmartScribeErrorType,
} from "../../components/FinishScriptForm/Subcomponents/FlaggedIssuesError/types";
import flaggedIssuesMessages from "../../components/FinishScriptForm/Subcomponents/FlaggedIssuesError/messages";
import { TreatmentType } from "../../components/FinishScriptForm/AcknowledgeErrors/types";
import { exists } from "../../util";
import {
  checkVetDeaLicenseExpired,
  checkVetDeaLicenseExpiring,
  checkVetDeaLicenseInvalid,
  checkVetLicenseExpired,
  checkVetLicenseExpiring,
  checkVetLicenseInvalid,
} from "../../components/FinishScriptForm/Subcomponents/AuthorizingVet/utils";

const CONTROLLED_REFILLS = ["0", "1", "2", "3", "4", "5"];
const ALL_REFILLS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "PRN"];

export const fetchPatientWeight = (
  patientWeight: string | undefined | null,
  patientWeightUnitCode: string | undefined | null,
) => {
  return exists(patientWeight) && exists(patientWeightUnitCode) && patientWeight !== "0"
    ? `${parseFloat(patientWeight).toFixed(3)} ${patientWeightUnitCode}`
    : null;
};

export const fetchClientName = (
  gpmPatientClientName: string | undefined | null,
  gpmClientFirstName: string | undefined | null,
  gpmClientLastName: string | undefined,
) => {
  return (
    gpmPatientClientName ||
    (gpmClientFirstName && gpmClientLastName ? `${gpmClientFirstName} ${gpmClientLastName}` : "")
  );
};

export const fetchCollapsedText = (
  gpmWeightWithUnits: string | null,
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  return `${gpmWeightWithUnits ? gpmWeightWithUnits + " • " : ""}${lastName ? lastName + ", " : ""}${
    firstName ? firstName : ""
    }`;
};

// Autoship should be recommended by default for ACUTE, DISEASE, SUPPLEMENT, OR DIET
export const shouldDefaultAutoShipOn = (product: Partial<ProductT>, approval: Partial<ApprovalT>, renewal: Partial<RenewalT>) => {
  if (approval) {
    return approval.isAutoship ? approval.isAutoship : false
  }
  if (renewal) {
    return renewal.isAutoship ? renewal.isAutoship : false
  }
  if (!product) {
    return false;
  }
  const { treatmentCategories } = product;
  return Boolean(
    treatmentCategories && treatmentCategories.some(categories => categories === TreatmentType.DIET || categories === TreatmentType.PREVENTATIVE))
};

const isAgeAcceptable = (clientDOB: string | null) => {
  if (clientDOB) {
    const today = new Date();
    const clientBirthDate = new Date(clientDOB);
    clientBirthDate.setDate(clientBirthDate.getDate() + 1);
    let currentAgeInYears = today.getFullYear() - clientBirthDate.getFullYear();
    const month = today.getMonth() - clientBirthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < clientBirthDate.getDate())) {
      currentAgeInYears--;
    }

    return currentAgeInYears >= 21;
  } else {
    return false;
  }
};

export const buildFlaggedIssues = (
  relevantFieldsForConflict: FieldsForConflict,
  smartScribe: ValidateSmartScribeT,
  reviewRx: Partial<Props["reviewRx"]>,
): FlaggedIssues => {
  const {
    clientDOB
  } = relevantFieldsForConflict;

  var flaggedWeight = null;
  var flaggedSpecies = null;
  var flaggedKeywords = null;
  var flaggedEssentialCopy = null;
  var flaggedClientId = null;
  
  smartScribe?.validation?.forEach(element => {
    if (element?.code === "WEIGHT_CONFLICT"){
      flaggedWeight = {
        weightMin: element.patientWeightMin,
        weightMax: element.patientWeightMax,
        selectedWeight: element.selectedWeight,
        required: true
      }
    }    
    if (element?.code === "SPECIES_CONFLICT"){
        flaggedSpecies = {
          expected: element?.values,
          selected: element?.selectedSpecies,
          required: true
      }
    }
    if (element?.code === "DIRECTIONS"){
      flaggedKeywords = {
        expected: " - ", 
        selected: element?.values, 
        required: true 
      }
    }
    if (element?.code === "ESSENTIAL_COPY"){
      flaggedEssentialCopy = {
        label: flaggedIssuesMessages.flaggedLabels.essentialCopy,
        expected: "",
        selected: "Yes",
        tooltip: "",
        errorType: SmartScribeErrorType.ERROR,
        required: false
      }
    }
    if (element?.code === "CLIENT_IDENTIFICATION") {
      flaggedClientId = {
        label: flaggedIssuesMessages.flaggedLabels.clientId,
        expected: "Yes",
        selected: "Not inserted",
        tooltip: flaggedIssuesMessages.tooltip.clientHasNoIdentification,
        errorType: SmartScribeErrorType.WARNING,
        required: false
      }
    }
  }); 

  const flaggedControlledProduct = reviewRx.isControlled
    ? !isAgeAcceptable(clientDOB)
      ? {
        expected: "21+",
        selected: "Under 21",
        required: false,
      }
      : null
    : null;


  let flaggedAuthorizingVetLicense = {};
  if (!checkVetLicenseInvalid(reviewRx.authorizingVet ?? "", reviewRx.authorizingVets ?? [])) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetLicenseInvalid,
      expected: "",
      selected: "Yes",
      tooltip: "",
      errorType: SmartScribeErrorType.ERROR,
      required: false,
    };
  } else if (!checkVetLicenseExpired(reviewRx.authorizingVet ?? "", reviewRx.authorizingVets ?? [], reviewRx.veterinarianLicenseKey ?? '')) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetLicenseExpired,
      expected: "",
      selected: "Yes",
      tooltip: flaggedIssuesMessages.tooltip.authorizingVetLicenseExpired,
      errorType: SmartScribeErrorType.ERROR,
      required: false,
    };
  } else if (!checkVetLicenseExpiring(reviewRx.authorizingVet ?? "", reviewRx.authorizingVets ?? [], reviewRx.veterinarianLicenseKey ?? '')) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetLicenseExpiring,
      expected: "",
      selected: "Yes",
      tooltip: flaggedIssuesMessages.tooltip.authorizingVetLicenseExpiring,
      errorType: SmartScribeErrorType.WARNING,
      required: false,
    };
  }

  let flaggedAuthorizingVetDeaLicense = {};
  if (
    !checkVetDeaLicenseInvalid(
      reviewRx.authorizingVet ?? "",
      reviewRx.authorizingVets ?? [],
      reviewRx.isControlled ?? false,
    )
  ) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetDeaLicenseInvalid,
      expected: "",
      selected: "Yes",
      tooltip: "",
      errorType: SmartScribeErrorType.ERROR,
      required: false,
    };
  } else if (
    !checkVetDeaLicenseExpired(
      reviewRx.authorizingVet ?? "",
      reviewRx.authorizingVets ?? [],
      reviewRx.isControlled ?? false,
    )
  ) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetDeaLicenseExpired,
      expected: "",
      selected: "Yes",
      tooltip: flaggedIssuesMessages.tooltip.authorizingVetDeaLicenseExpired,
      errorType: SmartScribeErrorType.ERROR,
      required: false,
    };
  } else if (
    !checkVetDeaLicenseExpiring(
      reviewRx.authorizingVet ?? "",
      reviewRx.authorizingVets ?? [],
      reviewRx.isControlled ?? false,
    )
  ) {
    flaggedAuthorizingVetLicense = {
      label: flaggedIssuesMessages.flaggedLabels.authorizingVetDeaLicenseExpiring,
      expected: "",
      selected: "Yes",
      tooltip: flaggedIssuesMessages.tooltip.authorizingVetDeaLicenseExpiring,
      errorType: SmartScribeErrorType.WARNING,
      required: false,
    };
  }

  // this returns an empty object if no issues are found
  return {
    ...(flaggedWeight ? { weight: flaggedWeight } : {}),
    ...(flaggedSpecies ? { species: flaggedSpecies } : {}),
    ...(flaggedKeywords ? { keywords: flaggedKeywords } : {}),
    ...(flaggedControlledProduct ? { controlledProduct: flaggedControlledProduct } : {}),
    ...(flaggedClientId ? { clientIdentification: flaggedClientId } : {}),
    ...(flaggedEssentialCopy ? { productIsEssentialCopy: flaggedEssentialCopy } : {}),
    ...(Object.keys(flaggedAuthorizingVetLicense).length
      ? { authorizingVetLicense: flaggedAuthorizingVetLicense }
      : {}),
    ...(Object.keys(flaggedAuthorizingVetDeaLicense).length
      ? { authorizingVetDeaLicense: flaggedAuthorizingVetDeaLicense }
      : {}),
  };
};

export const computeNumberOfRefills = (isControlled: boolean | null): string[] => {
  return isControlled
    ? CONTROLLED_REFILLS
    : ALL_REFILLS;
};

export const getTotalRefills = (refills: string | null, isControlled: boolean | null) => {
  if (refills === null) return null;
  if (isControlled) {
    return CONTROLLED_REFILLS.includes(refills) ? refills : null;
  } 
  return ALL_REFILLS.includes(refills) ? refills : null;
}

export const showUserPermissionWarningCard = (
  baseProduct: BaseProductT,
  product: ProductT,
  approval: ApprovalT,
  renewal: RenewalT,
  patient: PatientT,
  client: ClientT,
  user: FullPracticeUserT
) => {
  if (!product && !approval && !renewal) {
    return "No product was found.";
  }
  if (!patient) {
    return "No linked patient was found.";
  }
  if (!client) {
    return "No linked client was found.";
  }
  if (!user) {
    return "No practice user was found.";
  }
  if (user && user.practiceUserTypeCode !== 'VETERINARIAN'
    && product?.isControlled) {
    return "USER_MUST_BE_VET";
  }
  if (user && user.practiceUserTypeCode !== 'VETERINARIAN'
    && !user.vetDelegateLevels?.some(vetDelegateLevel => vetDelegateLevel?.delegationTypeCode === 'US_OTC_AOTC_PREVENTATIVE_RX')
    && product?.legalCategoryCode === 'RX'
    && !baseProduct?.treatmentCategories?.some(treatmentCategory => treatmentCategory === 'PREVENTATIVE' || treatmentCategory === 'DIET')) {
    return "BASIC_DELEGATE_USER";
  }
}
