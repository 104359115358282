import * as r from "ramda";
import { pims } from "./components/PimsLogo/PimsLogo";

/**
 * log and return a value with a tag on it;
 * designed for debugging
 * @param msg prepended to log entry
 * @param v whatever to log and return
 */
export function tag<T>(msg: string): (v: T) => T;
export function tag<T>(msg: string, v: T): T;
export function tag<T>(...args: any[]) {
  if (args.length === 2) {
    const [msg, v] = args as [string, T];

    // tslint:disable-next-line:no-console
    console.log(msg, v);
    return v;
  }
  // tslint:disable-next-line:ban
  return (v: T) => tag(args[0] as string, v);
}

/**
 * whether something is neither null nor empty
 */
export function exists<T>(t: T | null | undefined): t is T {
  return r.complement(r.either(r.isNil, r.isEmpty))(t);
}

/**
 * call functions on values only if they exist, i.e. aren't nil/undefined
 * @param fn the function to call
 * @param t the value to call if not null
 */
export function nullMap<T, U>(fn: (t: T) => U): (t: T | null | undefined) => U | null;
export function nullMap<T, U>(fn: (t: T) => U, t: T | null | undefined): U | null;
export function nullMap<T, U>(...args: any[]) {
  if (args.length >= 2) {
    const [fn, t] = args as [(t: T) => U, T];

    if (t == null) {
      return null;
    }

    return fn(t);
  }

  return (t: T | null) => nullMap(args[0], t);
}

/**
 * Whether a string is a valid non-nil guid or not
 * @param str
 */
export function isValidNonNilGuid(str: string): boolean {
  return r.test(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i, str);
}

declare var ShowURL: any;
/**
 * Calls a custom JS function from AVImark that opens a link in the OS's default browser
 * If ShowURL is undefined we'll log a warning instead of throwing an error
 * @param url the url that will be opened in an external browser
 */
export function avimarkShowUrl(url: string): void {
  try {
    ShowURL(url);
  } catch (e) {
    if (e instanceof ReferenceError) {
      // Catch if the user isn't in AVImark
      console.warn("ReferenceError: ShowURL is not defined");
    } else {
      throw e;
    }
  }
}

export const handleLink = (pimsType: string | null, link: string): (() => void) => {
  if (pimsType?.toLowerCase() === pims.avimark.toLowerCase()) {
    return () => avimarkShowUrl(link);
  }
  if (pimsType?.toLowerCase() === pims.eVetShort.toLowerCase()
      || pimsType?.toLowerCase() === pims.eVetLong.toLowerCase()) {
    return () => window.open(link, "_blank");
  }
  return () => window.open(link, "_blank");
};

export function pendoImpl(userKey: string,animalCareBusinessKey: string): void {
  const obj = {
    visitor: {
      vRxpro360_userKey: userKey,
       /*commented out and might require for future implementation
       rxpro360_givenName: user.data?.retrievePracticeUser?.givenName,
        rxpro360_familyName: user.data?.retrievePracticeUser?.familyName,
        rxpro360_email: user.data?.retrievePracticeUser?.email,
        rxpro360_roles: user.data?.retrievePracticeUser?.vetDelegateLevels,
        rxpro360_isActive: user.data?.retrievePracticeUser?.isActive,*/
       
    },
    account: {
      vRxpro360_acbkey: animalCareBusinessKey
    }
  };
  (window as any).pendo.initialize(obj);
}
